<gipi-abstract id="dialogRegisterUser"
               name="dialogRegisterUser"
               [dialog]="true">
    <gipi-toolbar mat-dialog-title
                  id="toolbarDialogRegisterUser">
        Cadastrar usuário
    </gipi-toolbar>

    <div mat-dialog-content
         fxLayout="column"
         fxLayoutGap="16px">
        <div fxLayout="row wrap"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0">
            <gipi-input-text [label]="(authoritiesValue !== 'ROLE_CLIENT') ? 'Nome' : 'Nome fantasia da empresa'"
                             id="userName"
                             name="userName"
                             [required]="true"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [(ngModel)]="entity.name"
                             [min]="2"
                             [max]="60"
                             [maxLength]="60"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-input-text>
            <gipi-input-text label="E-mail"
                             id="userEmail"
                             name="userEmail"
                             [type]="'email'"
                             [lowerCase]="true"
                             [required]="true"
                             [disabled]="loading || (typeOperation === 'VIEW') || isDisabledEmail"
                             [(ngModel)]="entity.email"
                             [min]="2"
                             [max]="100"
                             [maxLength]="100"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-input-text>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0">
            <gipi-input-select-enum label="Tipo de usuário"
                                    id="userTypeAuthority"
                                    name="userTypeAuthority"
                                    [disabled]="loading || (typeOperation === 'VIEW')"
                                    [required]="true"
                                    [valuesExclud]="excludedAuthorities"
                                    [enum]="authoritiesEnum"
                                    [(ngModel)]="authoritiesValue"
                                    fxFlex
                                    fxFlex.lt-md="100">
            </gipi-input-select-enum>
            <gipi-timepicker label="Início do horário de uso"
                             id="userTypeAuthority"
                             name="userTypeAuthority"
                             *ngIf="authoritiesValue !== 'ROLE_CLIENT'"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [required]="true"
                             [(ngModel)]="startTimeValue"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-timepicker>
            <gipi-timepicker label="Início do horário de uso"
                             id="userTypeAuthority"
                             name="userTypeAuthority"
                             *ngIf="authoritiesValue !== 'ROLE_CLIENT'"
                             [disabled]="loading || (typeOperation === 'VIEW')"
                             [required]="true"
                             [minDate]="startTimeValue"
                             [(ngModel)]="stopTimeValue"
                             fxFlex
                             fxFlex.lt-md="100">
            </gipi-timepicker>
        </div>
        <div fxLayout="row"
             fxLayoutGap="16px"
             fxLayoutGap.lt-md="0">
            <mat-slide-toggle *ngIf="authoritiesValue !== 'ROLE_CLIENT'"
                              [disabled]="loading || (typeOperation === 'VIEW')"
                              [(ngModel)]="permissionRegister">
                Permissão para realizar cadastro
            </mat-slide-toggle>
        </div>
    </div>

    <div mat-dialog-actions
         fxLayout="row wrap"
         fxLayout.lt-md="column wrap"
         fxLayoutAlign="end end"
         fxLayoutGap="16px">
        <gipi-button label="Salvar"
                     gipi-primary
                     id="btnSave"
                     name="btnSave"
                     *ngIf="(typeOperation !== 'VIEW')"
                     (click)="confirm()"
                     [disabled]="loading"
                     [loading]="loading"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
        <gipi-button label="Voltar"
                     gipi-secondary
                     id="btnReturn"
                     name="btnReturn"
                     (click)="close('NONE')"
                     fxFlex="30"
                     fxFlex.lt-md="100">
        </gipi-button>
    </div>
</gipi-abstract>